import * as yup from 'yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { zipCodeRegex } from '../../helpers';
import AddressAttributesInput, { AddressInputValue } from '../../ParticipantAttributes/Attributes/AddressInput';
import ParticipantFieldInputProps from '../ParticipantFieldInputProps';
import UI from '../../UI';
import useValidation from '../../useValidation';

export interface AddressValue extends AddressInputValue {
  enabled: boolean;
}

export const schema = yup.object({
  country: yup.string().required(),
  city: yup.string().required(),
  extra_address_line: yup.string().ensure(),
  house_number: yup.string().required().when(['country'], {
    is: (country: string) => country === 'NL',
    then: yup.string().required().test('invalid', (value) => (
      // Dutch house numbers should contain at least one digit
      !value || !!value.match(/\d/)
    )),
  }),
  street: yup.string().required(),
  zip_code: yup.string().required().when(['country'], {
    is: (country: string) => country === 'NL',
    then: yup.string().required().test('zip_code', (value) => (
      // Remove non-alphanumeric characters and check if it matches 1111AA
      !value || !!value.replace(/[^0-9a-z]/gi, '').match(zipCodeRegex.NL)
    )),
  }),
});

const AddressInput = ({ value, onChange, field, errors: extraErrors, validating }: ParticipantFieldInputProps) => {
  const { t } = useTranslation('common');

  const defaultValue: AddressValue = useMemo(() => ({
    country: null,
    city: null,
    extra_address_line: null,
    house_number: null,
    street: null,
    zip_code: null,
    enabled: field.required,
  }), [field.required]);

  const addressData: AddressValue = useMemo(() => ({
    ...defaultValue,
    ...value.value ? JSON.parse(value.value) : {},
  }), [defaultValue, value.value]);

  const form = useForm({
    values: addressData,
  });

  const showInput = form.values.enabled !== false;

  const setShowInput = (enabled: boolean) => {
    form.set((currentValue) => ({
      ...currentValue,
      enabled,
    }));
  };

  const toggleShowInput = () => {
    setShowInput(!showInput);
  };

  const { errors } = useValidation({
    schema,
    values: form.values,
    externalErrors: extraErrors,
  });

  /**
   * Used so that we can trigger change events only when the value has changed.
   */
  const valueRef = useRef(value.value);

  useEffect(() => {
    const newValue: string | null = JSON.stringify(form.values);

    if (newValue !== valueRef.current) {
      onChange({ value: newValue });
      valueRef.current = newValue;
    }
  }, [form.values, onChange]);

  const handleChange = (value: AddressInputValue) => {
    form.set((oldValue) => ({
      ...oldValue,
      ...value,
      enabled: oldValue.enabled,
    }));
  };

  const valid = !Object.keys(errors).length;

  return (
    <UI.InputGroup sc={{ valid }}>
      <UI.InputLabel>
        {field.title}
        {' '}
        {field.required && <UI.RequiredMark />}
        {validating && (
          <>
            {' '}
            <UI.Icon>
              <UI.Loader sc={{ brand: 'gray.400' }} />
            </UI.Icon>
          </>
        )}
      </UI.InputLabel>

      {field.description && (
        <UI.InputDescription>
          <UI.HTML html={field.description} />
        </UI.InputDescription>
      )}

      <UI.FormGrid sc={{ gutter: 0.5 }}>
        {!field.required && (
          <UI.Checkbox
            checked={!!showInput}
            onChange={toggleShowInput}
          >
            {t('show_address_input')}
          </UI.Checkbox>
        )}
        <UI.AnimateHeight isVisible={showInput}>
          <UI.SubForm>
            <AddressAttributesInput
              onChange={handleChange}
              idPrefix="ParticipantFields"
              onBlur={(key) => form.setTouched(key, true)}
              touched={form.touched}
              value={form.values}
              errors={errors}
              required
            />
          </UI.SubForm>
        </UI.AnimateHeight>
      </UI.FormGrid>
    </UI.InputGroup>
  );
};

export default AddressInput;
