import { X } from 'react-feather';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import uniq from 'lodash/uniq';

import { Gender as GenderType } from '__generated__/graphql';
import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';
import useProject from '../../useProject';

export interface GenderInputValue {
  gender?: GenderType | null;
}

const GenderInput = ({
  value, onChange, onBlur, touched, required = true, errors,
}: ParticipantAttributeInputProps<GenderInputValue>) => {
  const { t } = useTranslation('common');
  const project = useProject();

  const handleChange = (gender: GenderType | null) => {
    onChange({ gender });
    onBlur?.('gender');
  };

  // Make sure the gender that was already selected is always shown,
  // because the list of genders on the project can change.
  const { current: genders } = useRef(uniq([
    ...project.genders,
    ...(value.gender ? [value.gender] : []),
  ]));

  return (
    <UI.InputGroup sc={{ valid: !errors?.gender, touched: touched?.gender }}>
      <UI.InputLabel htmlFor={`Gender_${genders[0]}`}>
        {t('participant_attributes.gender')}
        {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
        )}
      </UI.InputLabel>
      <UI.RadioPill>
        {genders.map((gender) => (
          <UI.Radio
            onChange={() => handleChange(gender)}
            checked={value.gender === gender}
            sc={{ box: true }}
            id={`Gender_${gender}`}
            key={gender}
          >
            {t(`genders.${gender}`)}
          </UI.Radio>
        ))}
        {!required && value.gender && (
          <UI.Radio
            onChange={() => handleChange(null)}
            sc={{ box: true, hideRadio: true }}
            disabled={!value.gender}
            checked={false}
            aria-label={t('genders.none')}
          >
            <UI.Icon sc={{ color: 'gray.400' }}>
              <X />
            </UI.Icon>
          </UI.Radio>
        )}
      </UI.RadioPill>
      <UI.ErrorMessages
        attribute={t('participant_attributes.gender')}
        errors={errors?.gender}
      />
    </UI.InputGroup>
  );
};

export default GenderInput;
