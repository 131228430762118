
import { useTranslation } from 'react-i18next';
import UI from '../UI';
import useLocale from '../useLocale';

export interface PriceRangeProps {
  prices: Price[];
}

interface Price {
  amount?: number;
}

const PriceRange = ({ prices }: PriceRangeProps) => {
  const { t } = useTranslation('common');
  const { formatCurrency } = useLocale();

  let min: number | null = null;
  let max: number | null = null;

  prices.forEach(({ amount }) => {
    if (min === null) {
      min = amount;
      max = amount;
    } else {
      min = Math.min(min, amount);
      max = Math.max(max, amount);
    }
  });

  if (max === 0) {
    return <>{t('price_range.free')}</>;
  }

  if (min === max) {
    return <>{formatCurrency(min)}</>;
  }

  return (
    <>
      {formatCurrency(min)}
      <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
      {formatCurrency(max)}
    </>
  );
};

export default PriceRange;
