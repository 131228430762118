import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import ParticipantFieldInputProps, { ParticipantFieldChoice } from '../ParticipantFieldInputProps';
import UI from '../../UI';

const RADIO_THRESHOLD = 10;

const OptionInput = ({
  value, onChange, onBlur, field, disabledChoices = [], touched, errors, validating,
}: ParticipantFieldInputProps) => {
  const { t } = useTranslation('common');
  const idRef = useRef(Math.round(Math.random() * 1000000));

  const choices = sortBy(field.choices || [...field.enabled_choices, ...disabledChoices], 'position');
  const checked = value.choices.map(({ id }) => id);
  const showSelect = choices.length >= RADIO_THRESHOLD;

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const choiceId = event.target.value;

    onChange({ choices: choiceId ? [{ id: choiceId }] : [] });
    onBlur?.();
  };

  const toggle = (choice: ParticipantFieldChoice) => {
    onChange({ choices: [{ id: choice.id }] });
    onBlur?.();
  };

  return (
    <UI.InputGroup sc={{ valid: !errors?.choices, touched }}>
      <UI.InputLabel htmlFor={`Field_${idRef.current}_0`}>
        {field.title}
        {' '}
        {field.required && <UI.RequiredMark />}
        {validating && (
          <>
            {' '}
            <UI.Icon>
              <UI.Loader sc={{ brand: 'gray.400' }} />
            </UI.Icon>
          </>
        )}
      </UI.InputLabel>
      {field.description && (
        <UI.InputDescription>
          <UI.HTML html={field.description} />
        </UI.InputDescription>
      )}
      {showSelect && (
        <UI.Select
          onChange={handleChange}
          sc={{ placeholder: !value.choices[0] }}
          id={`Field_${idRef.current}_0`}
          value={checked[0] || ''}
        >
          <option value="">{t('option_field.select_option')}</option>
          {choices.map((choice) => (
            <option value={choice.id} disabled={!choice.enabled} key={choice.id}>
              {choice.title}
            </option>
          ))}
        </UI.Select>
      )}
      {!showSelect && (
        <>
          {choices.map((choice) => (
            <UI.Radio
              checked={checked.includes(choice.id)}
              onChange={() => toggle(choice)}
              key={choice.id}
            >
              <UI.Span sc={{ muted: !choice.enabled }}>
                {choice.title}
              </UI.Span>
            </UI.Radio>
          ))}
        </>
      )}
      <UI.ErrorMessages attribute={field.title} errors={errors?.choices} />
    </UI.InputGroup>
  );
};

export default OptionInput;
