import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';

import { ParticipantAttributes } from '__generated__/graphql';
import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';
import useProject from '../../useProject';

export interface PhoneInputValue {
  phone?: string | null;
  emergency_phone?: string | null;
  country?: string | null;
}

const PhoneInput = ({
  attribute, value, onChange, onBlur, touched, required = true, errors,
}: ParticipantAttributeInputProps<PhoneInputValue>) => {
  const { t } = useTranslation('common');
  const project = useProject();

  const key = attribute === ParticipantAttributes.emergency_phone ? 'emergency_phone' : 'phone';
  const country = value.country || project.organisation_country || 'NL';

  return (
    <UI.InputGroup sc={{ valid: !errors?.[key], touched: touched?.[key] }}>
      <UI.InputLabel htmlFor={`ParticipantPhone_${camelCase(key)}`}>
        {t(`participant_attributes.${key}`)}
        {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
        )}
      </UI.InputLabel>
      <UI.PhoneNumberInput
        value={value[key]}
        country={country}
        onChange={(value) => onChange({ [key]: value || null })}
        onBlur={() => onBlur?.(key)}
        id={`ParticipantPhone_${camelCase(key)}`}
      />
      <UI.ErrorMessages attribute={t(`participant_attributes.${key}`)} errors={errors?.[key]} />
    </UI.InputGroup>
  );
};

export default PhoneInput;
