import { useTranslation } from 'react-i18next';

import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';

export interface DateOfBirthInputValue {
  date_of_birth?: string | null;
}

const DateOfBirthInput = ({
  value, onChange, onBlur, touched, required = true, errors = {},
}: ParticipantAttributeInputProps<DateOfBirthInputValue>) => {
  const { t } = useTranslation('common');

  return (
    <UI.InputGroup sc={{ valid: !errors?.date_of_birth, touched: touched?.date_of_birth }}>
      <UI.InputLabel htmlFor="ParticipantDateOfBirth_Day">
        {t('participant_attributes.date_of_birth')}
        {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
        )}
      </UI.InputLabel>
      <UI.DateInput
        value={value.date_of_birth}
        onChange={(value) => onChange({ date_of_birth: value })}
        onTouch={() => onBlur?.('date_of_birth')}
        touched={touched?.date_of_birth}
        id="ParticipantDateOfBirth"
      />
      <UI.ErrorMessages
        attribute={t('participant_attributes.date_of_birth')}
        errors={errors.date_of_birth}
      />
    </UI.InputGroup>
  );
};

export default DateOfBirthInput;
