import { gql } from '__generated__';

export default gql(`
  query GetExternalValidationResult($input: ExternalValidationInput!) {
    participantFieldValidation(input: $input) {
      success
      message
      suggestion
    }
  }
`);
