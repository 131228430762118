
import upperFirst from 'lodash/upperFirst';

import { DateTimeFormat } from '../../config';
import UI from '../UI';
import useLocale from '../useLocale';

export interface DateRangeProps {
  start: string | null;
  end?: string | null;
  showDay?: boolean;
}

/**
 * @param start UTC start date.
 * @param end UTC end date.
 */
const DateRange = ({ start, end, showDay }: DateRangeProps) => {
  const { formatDate, parseDate } = useLocale();

  const startDate = parseDate(start, { timezone: 'UTC' });
  const endDate = end ? parseDate(end, { timezone: 'UTC' }) : null;

  const sameDay = endDate && (
    formatDate(startDate, { format: 'internal_date' })
      === formatDate(endDate, { format: 'internal_date' })
  );

  const startTime = formatDate(startDate, { format: 'internal_time' });
  const endTime = endDate ? formatDate(endDate, { format: 'internal_time' }) : null;
  const sameTime = startTime === endTime;

  const formatPrefix = showDay ? 'display_time_slot_' : 'display_';

  if (sameDay) {
    return (
      <>
        {upperFirst(formatDate(startDate, { format: `${formatPrefix}date` }))}
        {sameTime && startTime !== '00:00:00' && (
          `, ${upperFirst(formatDate(startDate, { format: `${formatPrefix}time` as DateTimeFormat }))}`
        )}
        {!sameTime && (
          <>
            {', '}
            {upperFirst(formatDate(startDate, { format: `${formatPrefix}time` as DateTimeFormat }))}
            <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
            {upperFirst(formatDate(endDate, { format: `${formatPrefix}time` as DateTimeFormat }))}
          </>
        )}
      </>
    );
  }

  if (startDate && endDate) {
    const format: DateTimeFormat = startTime === '00:00:00' && endTime === '00:00:00'
      ? `${formatPrefix}date` : `${formatPrefix}date_time`;

    return (
      <>
        {upperFirst(formatDate(startDate, { format }))}
        <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
        {upperFirst(formatDate(endDate, { format }))}
      </>
    );
  }

  const format: DateTimeFormat = startTime === '00:00:00' ? `${formatPrefix}date` : `${formatPrefix}date_time`;

  return <>{upperFirst(formatDate(startDate, { format }))}</>;
};

export default DateRange;
