import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useCountries from '../useCountries';

const CountryOptions = memo(() => {
  const { t } = useTranslation('common');
  const { countries } = useCountries();

  return (
    <>
      {countries.map((country) => (
        <option value={country} key={country}>{t(`common:countries.${country}`)}</option>
      ))}
    </>
  );
});

CountryOptions.displayName = 'CountryOptions';

export default CountryOptions;
