
import useLocale from '../useLocale';

interface Discount {
  type: 'percentage' | 'absolute';
  value: number;
}

const DiscountValue = ({ discount }: { discount: Discount }) => {
  const format = useDiscountFormatter();

  return <>{format(discount)}</>;
};

export const useDiscountFormatter = () => {
  const { formatInteger, formatCurrency } = useLocale();

  return (discount: Discount) => {
    if (discount.type === 'absolute') {
      const digits = discount.value % 100 === 0 ? 0 : 2;

      return formatCurrency(discount.value, {
        minimumFractionDigits: digits, maximumFractionDigits: digits,
      });
    }

    return `${formatInteger(discount.value)}%`;
  };
};

export default DiscountValue;
