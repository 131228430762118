import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import { Locale } from '__generated__/graphql';
import useLocale from './useLocale';

// Use the IDs of the Mollie payment method. We can do this because currently only Mollie is supported as PSP.
export enum PaymentMethod {
  BankTransfer = 'banktransfer',
  Ideal = 'ideal',
  ApplePay = 'applepay',
}

interface UsePaymentMethodsProps {
  country?: string;
  enabledPaymentMethods?: string[];
  bankTransferAllowed?: boolean;
}

const usePaymentMethods = ({
  country, enabledPaymentMethods = [], bankTransferAllowed = true,
}: UsePaymentMethodsProps = {}) => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();

  const bankTransferEnabled = enabledPaymentMethods.filter(
    (paymentMethod) => paymentMethod === PaymentMethod.BankTransfer,
  ).length > 0;

  const getPaymentMethodImageUrl = (paymentMethod: string) => (
    `https://www.mollie.com/external/icons/payment-methods/${encodeURI(paymentMethod === 'test' ? 'creditcard' : paymentMethod)}.svg`
  );
  const getPaymentMethodTitle = useCallback((paymentMethod: string) => t(`payment_methods.${paymentMethod}`), [t]);

  const allowedPaymentMethods = useMemo(() => {
    // Sort payment methods based on given country (i.e. of the buyer).
    // When no country is given, try to deduce it based on active locale.
    let localCountry = country;

    if (!localCountry) {
      if (locale === Locale.nl) {
        localCountry = 'NL';
      } else if (locale === Locale.de) {
        localCountry = 'DE';
      }
    }

    const list: string[] = [];

    // First the local payment methods.
    if (localCountry === 'NL') {
      list.push('ideal');
    } else if (localCountry === 'BE') {
      list.push('bancontact');
    } else if (localCountry === 'DE') {
      list.push('sofort');
      list.push('giropay');
    }

    // Followed by global payment methods
    list.push('applepay');
    list.push('creditcard');
    list.push('paypal');

    // Followed by the sorted list of all other enabled payment methods.
    list.push(...sortBy(enabledPaymentMethods.filter((paymentMethod) => (
      list.indexOf(paymentMethod) === -1 && paymentMethod !== PaymentMethod.BankTransfer
    )), (paymentMethod) => getPaymentMethodTitle(paymentMethod).toLowerCase()));

    // Followed by bank transfer
    list.push('banktransfer');

    const isEnabledPaymentMethod = (paymentMethod: string) => (
      enabledPaymentMethods.indexOf(paymentMethod) > -1
    );

    const isAllowedPaymentMethod = (paymentMethod: string) => {
      if (paymentMethod === PaymentMethod.BankTransfer) {
        return bankTransferAllowed;
      }

      if (paymentMethod === PaymentMethod.ApplePay) {
        return supportsApplePay();
      }

      return true;
    };

    return list.filter((paymentMethod) => (
      isEnabledPaymentMethod(paymentMethod) && isAllowedPaymentMethod(paymentMethod)
    ));
  }, [enabledPaymentMethods, bankTransferAllowed, country, locale, getPaymentMethodTitle]);

  return {
    bankTransferEnabled,
    allowedPaymentMethods,
    getPaymentMethodImageUrl,
    getPaymentMethodTitle,
  };
};

export type PaymentMethodsInfo = ReturnType<typeof usePaymentMethods>;

const supportsApplePay = () => {
  let supported = false;

  try {
    if (window.ApplePaySession?.canMakePayments()) {
      supported = true;
    }
  } catch (error) {
    //
  }

  return supported;
};

export default usePaymentMethods;
