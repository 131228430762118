import upperFirst from 'lodash/upperFirst';

import useLocale from '../useLocale';

export interface TimeSlotFormatterProps {
  start_date: string;
  start_time?: string | null;
  multi_date: boolean;
}

// Format the time slot time, only showing the date part for time slots that span multiple days.
const useTimeSlotFormatter = () => {
  const { formatDate, parseDate } = useLocale();

  return (timeSlot: TimeSlotFormatterProps) => {
    const format = timeSlot.start_time
      ? (timeSlot.multi_date ? 'display_time_slot_date_time' : 'display_time')
      : 'display_time_slot_date';

    return upperFirst(formatDate(
      parseDate(`${timeSlot.start_date} ${timeSlot.start_time || '00:00:00'}`),
      { format },
    ));
  };
};

export default useTimeSlotFormatter;
