import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';

interface NameInputValue {
  first_name?: string | null;
  last_name?: string | null;
}

const NameInput = forwardRef(({
  value, onChange, onFocus, onBlur, touched, required = true, errors,
}: ParticipantAttributeInputProps<NameInputValue>, ref: any) => {
  const { t } = useTranslation('common');

  const handleChange = (field: keyof NameInputValue) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({ [field]: event.target.value });
    }
  );

  const bindElement = (name: string) => (element: HTMLInputElement) => {
    if (ref) {
      ref.current[name] = element;
    }
  };

  return (
    <UI.FormGrid sc={{ columns: '1fr 1fr' }}>
      <UI.InputGroup sc={{ valid: !errors?.first_name, touched: touched?.first_name }}>
        <UI.InputLabel htmlFor="ParticipantFirstName">
          {t('participant_attributes.first_name')}
          {required && (
            <>
              {' '}
              <UI.RequiredMark />
            </>
          )}
        </UI.InputLabel>
        <UI.DebouncedInput
          value={value.first_name}
          onChange={handleChange('first_name')}
          onBlur={() => onBlur?.('first_name')}
          onFocus={() => onFocus?.('first_name')}
          ref={bindElement('first_name')}
          id="ParticipantFirstName"
        />
        <UI.ErrorMessages attribute={t('participant_attributes.first_name')} errors={errors?.first_name} />
      </UI.InputGroup>
      <UI.InputGroup sc={{ valid: !errors?.last_name, touched: touched?.last_name }}>
        <UI.InputLabel htmlFor="ParticipantLastName">
          {t('participant_attributes.last_name')}
          {required && (
            <>
              {' '}
              <UI.RequiredMark />
            </>
          )}
        </UI.InputLabel>
        <UI.DebouncedInput
          value={value.last_name}
          onChange={handleChange('last_name')}
          onBlur={() => onBlur?.('last_name')}
          onFocus={() => onFocus?.('last_name')}
          ref={bindElement('last_name')}
          id="ParticipantLastName"
        />
        <UI.ErrorMessages attribute={t('participant_attributes.last_name')} errors={errors?.last_name} />
      </UI.InputGroup>
    </UI.FormGrid>
  );
});

export default NameInput;
