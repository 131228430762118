import * as yup from 'yup';
import intersection from 'lodash/intersection';
import map from 'lodash/map';

import {
  ParticipantFieldEntryInput,
  ParticipantFieldScope,
  ParticipantFieldType,
} from '__generated__/graphql';
import { schema as addressInputSchema } from './Types/AddressInput';
import { formatTime, validateIban } from '../helpers';

interface ParticipantFieldChoice {
  id: string;
  title: string;
  enabled: boolean;
  position: number;
}

export interface ParticipantField {
  id: string;
  type: ParticipantFieldType;
  scope: ParticipantFieldScope;
  title: string;
  // Either all choices, or only the enabled choices are loaded.
  choices?: ParticipantFieldChoice[];
  enabled_choices?: ParticipantFieldChoice[];
  required: boolean;
  required_promotions: Promotion[];
  config?: string | null;
  has_external_validation: boolean;
  enabled?: boolean;
}

interface Promotion {
  id: string;
}

interface ValidateFieldEntryProps {
  fieldEntry: ParticipantFieldEntryInput;
  field: Pick<ParticipantField, 'type' | 'required' | 'config'>;
  context?: yup.TestContext;
}

export const validateFieldEntry = ({ fieldEntry, field }: ValidateFieldEntryProps) => {
  if (!field.required) {
    // If the field is optional and has neither value nor choices, it is valid
    if (!fieldEntry.value && (!fieldEntry.choices || fieldEntry.choices.length === 0)) {
      return true;
    }
  }

  if (field.type === ParticipantFieldType.short_text) {
    return !!fieldEntry.value;
  }

  if (field.type === ParticipantFieldType.integer) {
    return !!fieldEntry.value;
  }

  if (field.type === ParticipantFieldType.iban) {
    return validateIban((fieldEntry.value || '').replace(/[^a-z0-9]/gi, ''));
  }

  if (field.type === ParticipantFieldType.option) {
    return fieldEntry.choices.length > 0;
  }

  if (field.type === ParticipantFieldType.selection) {
    return fieldEntry.choices.length > 0;
  }

  if (field.type === ParticipantFieldType.time) {
    return fieldEntry.value !== null;
  }

  if (field.type === ParticipantFieldType.address) {
    try {
      const value = JSON.parse(fieldEntry.value);

      if (value.enabled) {
        addressInputSchema.validateSync(value);
      }
    } catch (error) {
      return false;
    }

    return true;
  }

  if (field.type === ParticipantFieldType.au_license) {
    if (fieldEntry.value === null) {
      return false;
    }

    const data: AuLicenseData = JSON.parse(fieldEntry.value);

    const parsedConfig = parseConfig(field.config);

    if (!data.club_code) {
      return false;
    }

    if (parsedConfig.require_member_id && !data.member_id) {
      return false;
    }

    return true;
  }

  if (field.type === ParticipantFieldType.knwu_license) {
    return !!fieldEntry.value;
  }

  if (field.type === ParticipantFieldType.kwbn_member) {
    return !!fieldEntry.value;
  }

  if (field.type === ParticipantFieldType.ntfu_member) {
    return !!fieldEntry.value;
  }

  return true;
};

// Returns false on validation errors and returns a ValidationError when a yup context is passed.
const validateFieldEntryMinValue = ({ fieldEntry, field, context }: ValidateFieldEntryProps) => {
  const { value } = fieldEntry;

  if (value === null) {
    return true;
  }

  const config = parseConfig(field.config);

  if (field.type === ParticipantFieldType.time) {
    const min = config.min || null;

    if (min !== null && parseInt(value, 10) < min) {
      return context?.createError({
        params: {
          min: formatTime(min),
        },
      }) || false;
    }
  }

  return true;
};

// Returns false on validation errors and returns a ValidationError when a yup context is passed.
const validateFieldEntryMaxValue = ({ fieldEntry, field, context }: ValidateFieldEntryProps) => {
  const { value } = fieldEntry;

  if (value === null) {
    return true;
  }

  const config = parseConfig(field.config);

  if (field.type === ParticipantFieldType.time) {
    const max = config.max || null;

    if (max !== null && parseInt(value, 10) > max) {
      return context?.createError({
        params: {
          max: formatTime(max),
        },
      }) || false;
    }
  }

  return true;
};

export const getParticipantFieldEntrySchema = (
  participantFields: Pick<ParticipantField, 'id' | 'type' | 'required' | 'config'>[],
) => {
  const getField = (context: yup.TestContext) => participantFields.filter(
    ({ id }) => id === context.parent.participant_field.id,
  )[0];

  const isChoiceField = (field: Pick<ParticipantField, 'type'>) => (
    [ParticipantFieldType.option, ParticipantFieldType.selection].includes(field.type)
  );

  const validateValue = (value: ParticipantFieldEntryInput['value'], context: yup.TestContext) => {
    const field = getField(context);

    return !field || isChoiceField(field)
      || validateFieldEntry({ fieldEntry: { value, participant_field: { id: field.id } }, field });
  };

  const validateMinValue = (value: ParticipantFieldEntryInput['value'], context: yup.TestContext) => {
    const field = getField(context);

    return !field || isChoiceField(field)
      || validateFieldEntryMinValue({ fieldEntry: { value, participant_field: { id: field.id } }, field, context });
  };

  const validateMaxValue = (value: ParticipantFieldEntryInput['value'], context: yup.TestContext) => {
    const field = getField(context);

    return !field || isChoiceField(field)
      || validateFieldEntryMaxValue({ fieldEntry: { value, participant_field: { id: field.id } }, field, context });
  };

  const validateChoices = (choices: ParticipantFieldEntryInput['choices'], context: yup.TestContext) => {
    const field = getField(context);

    return !field || !isChoiceField(field)
      || validateFieldEntry({ fieldEntry: { choices, participant_field: { id: field.id } }, field });
  };

  return yup.object({
    value: yup.mixed()
      .test('required', '', (value: ParticipantFieldEntryInput['value'], context) => validateValue(value, context))
      .test('min.numeric', '', (value: ParticipantFieldEntryInput['value'], context) => (
        validateMinValue(value, context)
      ))
      .test('max.numeric', '', (value: ParticipantFieldEntryInput['value'], context) => (
        validateMaxValue(value, context)
      )),
    choices: yup.mixed()
      .test('required', '', (value: ParticipantFieldEntryInput['choices'], context) => validateChoices(value, context)),
  });
};

/**
 * Returns all fields with the order scope that are activated for the given set of promotion IDs.
 */
export function getOrderFields<T extends ParticipantField>(fields: T[], promotionIds?: string[]) {
  return fields.filter((field) => field.scope === ParticipantFieldScope.order && (
    !promotionIds || fieldIsActive(field, promotionIds)
  ));
}

/**
 * Indicates whether the field should be activated for the given promotion IDs.
 */
const fieldIsActive = (field: ParticipantField, promotionIds: string[]) => (
  field.required_promotions.length === 0
    || intersection(field.required_promotions.map(({ id }) => id), promotionIds).length > 0
);

export function getPromotionFields<T extends ParticipantField>(fields: T[], promotionId: string) {
  return fields.filter((field) => field.scope === ParticipantFieldScope.purchase
    && map(field.required_promotions, 'id').includes(promotionId));
}

const prefillFieldEntry = (values: ParticipantFieldEntryInput[], fieldId: string) => (
  values?.filter((value) => value.participant_field.id === fieldId)[0] || {
    participant_field: {
      id: fieldId,
    },
    value: null,
    choices: [],
  } as ParticipantFieldEntryInput
);

export const prefillFieldEntries = (
  values: ParticipantFieldEntryInput[], fields: { id: string; }[] = [],
) => (
  fields.map((field) => prefillFieldEntry(values, field.id))
);

export const getDisabledChoices = (
  field: Partial<Pick<ParticipantField, 'choices' | 'enabled_choices'>>, fieldEntry: ParticipantFieldEntry,
) => {
  if (field.choices) {
    // All choices are offered (i.e. in the back-end). No need to append disabled choices.
    return [];
  }

  const disabledChoices: ParticipantFieldChoice[] = [];
  const enabledChoiceIds = field.enabled_choices.map(({ id }) => id);

  fieldEntry.choice_entries.forEach(({ choice }) => {
    if (!enabledChoiceIds.includes(choice.id)) {
      disabledChoices.push({ ...choice, enabled: false });
    }
  });

  return disabledChoices;
};

export interface ParticipantFieldChoiceEntry {
  choice: {
    id: string;
    title: string;
    enabled: boolean;
    position: number;
  };
}

export interface ParticipantFieldEntry {
  id?: string | null;
  field: {
    id: string;
  };
  value?: string | null;
  choice_entries: ParticipantFieldChoiceEntry[];
}

export const convertToParticipantFieldEntryInput = (fieldEntry: ParticipantFieldEntry) => ({
  id: fieldEntry.id,
  participant_field: { id: fieldEntry.field.id },
  value: fieldEntry.value,
  choices: fieldEntry.choice_entries.map(({ choice: { id } }) => ({ id })),
});

export interface AuLicenseData {
  club_code: string | null;
  club_name: string | null;
  member_id: string | null;
}

export const parseConfig = (config: string | null) => (config ? JSON.parse(config) : {}) as any;

export default undefined;
