import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export interface AddressProps {
  street?: string | null;
  house_number?: string | null;
  zip_code?: string | null;
  city?: string | null;
  extra_address_line?: string | null;
  country?: string | null;
  [key: string]: any;
}

export const containsAddress = (address: AddressProps) => (
  address.street
    || address.house_number
    || address.zip_code
    || address.city
    || address.extra_address_line
    || address.country
);

const Address = ({
  street, house_number, zip_code, city, country, extra_address_line,
}: AddressProps) => {
  const { t } = useTranslation('common');

  const lines = [];

  if (street || house_number) {
    lines.push(`${street || ''} ${house_number || ''}`.trim());
  }

  if (extra_address_line) {
    lines.push(extra_address_line);
  }

  if (zip_code || city) {
    lines.push(`${zip_code || ''} ${city || ''}`.trim());
  }

  if (country) {
    lines.push(t(`countries.${country}`));
  }

  return (
    <>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {' '}
          {index < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};

export default Address;
